import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const InvoiceSchedulerApiService = {
  async getInvoiceSchedule({ accountId, from, to }) {
    try {
      const { data } = await HTTP.get('invoices/scheduler/calendar', {
        params: {
          accountId, from, to,
        },
      });

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  // format - pdf | csv | xlsx
  async getInvoiceFileURL({ invoiceId, format }) {
    try {
      const { data } = await HTTP.get(`invoices/${invoiceId}/download`, {
        params: {
          format,
        },
      });

      return data.url || '';
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
